export default [
  {
    icon: {
      name: "heroicons-outline:chat-bubble-oval-left-ellipsis",
      class: "w-5 h-5",
    },
    activePath: "/inapintar",
    name: "Inapintar",
    // permissions: ["Inapintar"],
    children: [
      // {
      //   name: "Chat Stream",
      //   to: "/inapintar/chat-stream",
      //   icon: {
      //     name: "la:dot-circle",
      //     class: "w-4 h-4",
      //   },
      //   permissions: ["Chat Stream Inapintar"],
      // },
      {
        name: "Chat",
        to: "/inapintar/chat",
        icon: {
          name: "la:dot-circle",
          class: "w-4 h-4",
        },
        permissions: ["Chat Inapintar"],
      },
      // {
      //   name: "Chat Compare SAI V LLAMA",
      //   to: "/inapintar/chat-compare-sai-v-llama",
      //   icon: {
      //     name: "la:dot-circle",
      //     class: "w-4 h-4",
      //   },
      //   // permissions: ["Chat Inapintar"],
      // },
      {
        name: "Chat Sahabat AI",
        to: "/inapintar/chat-sahabat-ai",
        icon: {
          name: "la:dot-circle",
          class: "w-4 h-4",
        },
        // permissions: ["Chat Inapintar"],
      },
      // {
      //   name: "Chat Rag",
      //   to: "/inapintar/chat-rag",
      //   icon: {
      //     name: "la:dot-circle",
      //     class: "w-4 h-4",
      //   },
      //   permissions: ["Chat Rag Inapintar"],
      // },
      {
        name: "Chat Rag Ayat",
        to: "/inapintar/chat-rag-ayat",
        icon: {
          name: "la:dot-circle",
          class: "w-4 h-4",
        },
        permissions: ["Chat Rag Ayat Inapintar"],
      },
      {
        name: "Scoring Chat",
        to: "/inapintar/scoring-chat",
        icon: {
          name: "la:dot-circle",
          class: "w-4 h-4",
        },
        // permissions: ["Scoring Chat Inapintar"],
      },
      // {
      //   name: "Scoring Chat Pasal Ayat",
      //   to: "/inapintar/scoring-chat-pasal-ayat",
      //   icon: {
      //     name: "la:dot-circle",
      //     class: "w-4 h-4",
      //   },
      //   // permissions: ["Scoring Chat Pasal Ayat Inapintar"],
      // },
      // {
      //   name: "Scoring Chat Pasal Ayat V2",
      //   to: "/inapintar/scoring-chat-pasal-ayat-v2",
      //   icon: {
      //     name: "la:dot-circle",
      //     class: "w-4 h-4",
      //   },
      //   // permissions: ["Scoring Chat Pasal Ayat Inapintar"],
      // },
      {
        name: "Master System Prompt",
        to: "/inapintar/master-system-prompt",
        icon: {
          name: "la:dot-circle",
          class: "w-4 h-4",
        },
        permissions: ["Master System Prompt Inapintar"],
      },
      // {
      //   name: "Scoring Question",
      //   to: "/inapintar/scoring-question",
      //   icon: { name: "la:dot-circle", class: "w-4 h-4" },
      //   permissions: ["Scoring Question Inapintar"]
      // },
      // {
      //   name: "Score",
      //   to: "/inapintar/score",
      //   icon: { name: "la:dot-circle", class: "w-4 h-4" },
      //   permissions: ["Score Inapintar"]
      // },
    ],
  },
]
