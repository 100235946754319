export default [
  {
    name: "Budgeting",
    activePath: "/budgeting",
    icon: { name: "mdi:faq", class: "w-5 h-5" },
    permissions: ["Budgeting"],
    children: [
      {
        name: "Revenue Projection",
        to: "/budgeting/revenue-projection",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["Revenue Projection Budgeting"],
      },
      // {
      //   name: "Peraturan Pajak",
      //   to: "/inadata/peraturan-pajak",
      //   icon: { name: "la:dot-circle", class: "w-4 h-4" },
      // },
    ],
  },
]
